import React from "react"

import Layout from "../components/templates/layoutWithTitle"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout title="404 - Page not Found">
    <SEO title="404: Not Found" />
    <div className="mt-2 mb-5 pb-5">
      <p className="mb-5">The link is broken or the page has benn moved.</p>
    </div>
  </Layout>
)

export default NotFoundPage
